@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

// HTML Component
// ============================================
.html-component {
    padding: $space-32 0;
}

.html-component-table {
    tr {
        td {
            width: 33.33%;

            &:not(:first-child) {
                text-align: center;
            }
        }
    }

    thead {
        td {
            border: 0;
            font-size: $font-16;
            font-weight: bold;
            padding: $space-16 $space-24;
            width: 33.33px;

            @include media-breakpoint-up(lg) {
                font-size: $font-26;
                padding: $space-20 $space-32;
            }
        }
    }

    tbody {
        td {
            border: 0;
            border-bottom: 1px solid #dbdbdb;
            font-size: $font-14;
            font-weight: bold;
            padding: $space-8 $space-16;

            @include media-breakpoint-up(lg) {
                font-size: $font-23;
                padding: $space-32;

                &:not(:first-child) {
                    font-size: $font-18;
                    font-weight: normal;
                }
            }
        }
    }
}

.category-seo-container {
    margin-bottom: $space-56;

    @include media-breakpoint-up(lg) {
        margin: 0 auto $space-64 auto;
        max-width: 990px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: #000;
        font-family: $font-questa;
        font-size: $font-20;
        font-weight: $font-black;
        margin-bottom: $space-16;
    }

    p,
    span {
        color: $color-t300;
        font-family: $font-sofia;
        font-size: $font-14;
        font-weight: $font-regular;
        line-height: 120%;
        margin-bottom: $space-16;
    }

    a {
        color: $color-b300;
        font-family: $font-sofia;
        font-size: $font-14;
        font-weight: $font-medium;
        line-height: 120%;
        text-decoration: underline;
    }

    .link-read-more {
        color: $color-b300;
        font-family: $font-sofia;
        font-size: $font-14;
        font-weight: $font-regular;
        font-weight: $font-bold;
        text-decoration: underline;
    }
}
